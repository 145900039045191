<template>

  <div class="row" ref="itemView">

    <div class="col-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
        <card>
        <div slot="header">
          <div class="header-content">
            <div class="form-title">
              <h4 class="card-title">
                {{ formTitle }}
                <span style="color:#938f8f;font-size: 15px;margin-left: 10px" v-if="registrationDetails && registrationDetails.session">
                  <span v-html="registrationDetails.session.session_name_cms"></span> <!-- <span style="color: #EC6459; font-size: 13px">{{ registrationDetails.session.days }}</span>--></span>
                <span v-if="registrationDetails && registrationDetails.cycle" style="color:#938f8f;font-size: 15px;margin-left: 10px">{{ registrationDetails.cycle.name }}</span>
              </h4>
             <div v-if="registrationDetails && registrationDetails.cycle">
               <div v-for="session in registrationDetails.cycle.training_sessions"
                    style="color: #939193;font-size: 14px;">
                 <span v-html="session.session_name_cms"></span>
               </div>
             </div>
            </div>
          </div>
        </div>

        <div id="contactus-message" class="card-body">
          <div class="container">
            <div class="row contact-info">
              <div class="col-12 col-md-12 col-lg-12">
               <div class="member-box">
                 <span class="title-label">{{ $store.getters['languageMixin/getStringFromLanguage']('Personal Information') }}</span>
                 <div class="row">
                   <div class="col-md-9">
                     <div class="row">
                       <div class="col-md-6 typo-line">
                         <p>
                           <span class="category category-2">{{ $store.getters['languageMixin/getStringFromLanguage']('Full Name') }}</span>
                           <span class="category-value" v-html="getValueOrDash(memberDetails, 'full_name_cms')"></span>
                         </p>
                       </div>
                     </div>
                     <div class="row">
                       <div class="typo-line">
                         <p>
                           <span class="category category-2">{{ $store.getters['languageMixin/getStringFromLanguage']('Email') }}</span>
                           <span class="category-value"> {{ getValueOrDash(memberDetails, 'email') }}</span>
                         </p>
                       </div>
                     </div>
                     <div class="row">
                       <div class="col-md-6 typo-line">
                         <p>
                           <span class="category category-2">{{ $store.getters['languageMixin/getStringFromLanguage']('Phone') }}</span>
                           <span class="category-value"> {{ getValueOrDash(memberDetails, 'phone') }}</span>
                         </p>
                       </div>
                       <div class="col-md-6 typo-line">
                         <p>
                           <span class="category category-2">{{ $store.getters['languageMixin/getStringFromLanguage']('Other Phone') }}</span>
                           <span class="category-value"> {{ getValueOrDash(memberDetails, 'other_phone') }}</span>
                         </p>
                       </div>
                     </div>
                     <div class="row">
                       <div class="typo-line">
                         <p>
                           <span class="category category-2">{{ $store.getters['languageMixin/getStringFromLanguage']('Address') }}</span>
                           <span class="category-value"> {{ getValueOrDash(memberDetails, 'address') }}</span>
                           <br>
                           <span class="category-value"> {{ getValueOrDash(memberDetails, 'po_box') }}</span>
                           <span class=""> {{ getValueOrDash(memberDetails, 'city') }}</span>
                           <span class=""> {{ getValueOrDash(memberDetails, 'country') }}</span>
                         </p>
                       </div>
                     </div>
                   </div>
                   <div class="col-md-3">
                     <img class="member-profile-image" :src="showImage(getValueOrDash(memberDetails, 'profile_image'))" alt="Profile Image"/>
                     <div style="margin-top: 10px">
                       <div>
                         <span>{{ $store.getters['languageMixin/getStringFromLanguage']('Source') }}</span>
                         <span class="category-value" style="margin-left: 20px">{{ $store.getters['languageMixin/getStringFromLanguage']('admin') }}</span>
                       </div>
                       <div v-if="memberDetails">
                         <span>{{ $store.getters['languageMixin/getStringFromLanguage']('Is Active')}}</span>
                         <span class="category-value" style="margin-left: 20px;padding: 3px; color: #ffffff;border-radius: 6px" :style="{backgroundColor: memberDetails && memberDetails.is_active ? 'green' : 'red'}">{{ memberDetails.is_active ? 'yes' : 'no'}}</span>
                       </div>
                       <div>
                         <span>{{ $store.getters['languageMixin/getStringFromLanguage']('Member Type')}}</span>
                         <span class="category-value" style="margin-left: 20px"> {{ getValueOrDash(memberDetails, 'type').name }}</span>
                       </div>
                       <div>

                         <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['members/get'])"
                            class="btn btn-info btn-wd btn-simple btn-link"
                            :to="'../../members/show/' + getValueOrDash(memberDetails, 'id')"
                            style="background-color: #23CCEF !important; color: #ffffff !important;padding: 10px;"
                            >
                           {{ $store.getters['languageMixin/getStringFromLanguage']('Show Member Details') }}
                           <span class="btn-label">
                      <i class="fa fa-eye"></i>
                        </span></router-link>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
              </div>


              <div class="col-12 col-md-12 col-lg-12">
                <div class="member-box">
                  <span class="title-label">{{ $store.getters['languageMixin/getStringFromLanguage']('Registration Information') }}</span>
                  <div v-if="registrationDetails && registrationDetails.book_for_assistant">
                    <label style="color: rgb(236, 100, 89);font-weight: bold;font-size: 18px;">{{ $store.getters['languageMixin/getStringFromLanguage']('Registration For  assistant') }}</label>
                    <div>
                      <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Assistant Name') }}</label>
                      <span style="margin-left: 20px">{{ registrationDetails.assistant_name }}</span>
                    </div>
                    <div>
                      <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Assistant Email') }}</label>
                      <span style="margin-left: 20px">{{ registrationDetails.assistant_email }}</span>
                    </div>
                    <div>
                      <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Assistant Phone') }}</label>
                      <span style="margin-left: 20px">{{ registrationDetails.assistant_phone }}</span>
                    </div>
                    <hr>
                  </div>
                  <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Member notes') }}: </label>
                  <p>
                    {{ registrationDetails ? registrationDetails.member_notes : '-' }}
                  </p>
                  <ValidationProvider
                    vid="notes"
                    rules=""
                    name="The Admin Notes"
                    v-slot="{ passed, failed,errors }">
                    <fg-text type="text"
                              :error="failed ? errors[0]: null"
                              :label="$store.getters['languageMixin/getStringFromLanguage']('Admin Notes')"
                              name="admin_notes"
                              fou
                              v-model="formData.admin_notes">
                    </fg-text>
                  </ValidationProvider>
                  <div class="col-md-12">
                    <ValidationProvider
                      vid="status"
                      rules=""
                      name="The Status"
                      v-slot="{ passed, failed,errors }">
                      <fg-select
                        name="status"
                        size="large"
                        filterable
                        clearable
                        :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Status')"
                        :error="failed ? errors[0]: null"
                        :input-classes="'select-default'"
                        :label="$store.getters['languageMixin/getStringFromLanguage']('Status')"
                        :list="builderData.registrationStatusList"
                        :listItemLabel="'fr'"
                        :listItemValue="'value'"
                        v-model="formData.status">
                      </fg-select>
                    </ValidationProvider>
                  </div>
                  <div v-if="registrationDetails && registrationDetails.coupon_id">
                    <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Coupon') }}: </label>
                    <p>
                      {{ $store.getters['languageMixin/getStringFromLanguage']('Coupon Code') }} :{{ registrationDetails ? registrationDetails.coupon_code : '-' }}
                     ({{ $store.getters['languageMixin/getStringFromLanguage']('Discount') }} : {{ registrationDetails ? registrationDetails.coupon_discount_amount : '-' }} )
                    </p>
                  </div>
                  <div v-if="registrationDetails && registrationDetails.received_referral_coupon_id">
                    <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Referral Coupon') }}: </label>
                    <p>
                      {{ $store.getters['languageMixin/getStringFromLanguage']('Refer to') }} :{{ registrationDetails && registrationDetails.codeReferFrom ? registrationDetails.codeReferFrom.full_name : '-' }}
                    </p>
                  </div>
                  <div v-if="registrationDetails && registrationDetails.referral_coupon_id">
                    <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Benefit of Referral Coupon')}}: </label>
                    <p>
                      {{ $store.getters['languageMixin/getStringFromLanguage']('Benefit from') }} :{{ registrationDetails && registrationDetails.codeReferTo ? registrationDetails.codeReferTo.full_name : '-' }}
                      ({{ $store.getters['languageMixin/getStringFromLanguage']('Discount') }} : {{ registrationDetails ? registrationDetails.referral_coupon_discount_amount : '-' }} )
                    </p>
                  </div>
                </div>
                <label class="payment_text">
                  {{ $store.getters['languageMixin/getStringFromLanguage']('Total Payment') + '(€' + registrationDetails.payment_amount + ')'}}
                </label>
                <div class="row">
                  <div class="col-md-4">
                    <label class="payment_text">
                      {{ $store.getters['languageMixin/getStringFromLanguage']('Payment of deposit') + '(€' + registrationDetails.payment_deposit_price + ')'}}
                    </label>
                    <div class="col-md-12">
                      <ValidationProvider
                        vid="payment_status"
                        rules=""
                        name="The Payment Status"
                        v-slot="{ passed, failed,errors }">
                        <fg-select
                          name="status"
                          size="large"
                          filterable
                          clearable
                          :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Payment Status')"
                          :error="failed ? errors[0]: null"
                          :input-classes="'select-default'"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('Payment Status')"
                          :list="builderData.paymentStatusList"
                          :listItemLabel="'fr'"
                          :listItemValue="'value'"
                          v-model="formData.payment_deposit_status">
                        </fg-select>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-12">
                      <ValidationProvider
                        vid="payment_method"
                        rules=""
                        name="The Payment Method"
                        v-slot="{ passed, failed,errors }">
                        <fg-select
                          name="payment_method"
                          size="large"
                          filterable
                          clearable
                          :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Payment Method')"
                          :error="failed ? errors[0]: null"
                          :input-classes="'select-default'"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('Payment Method')"
                          :list="builderData.paymentMethodList"
                          :listItemLabel="'fr'"
                          :listItemValue="'value'"
                          v-model="formData.payment_deposit_method">
                        </fg-select>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-12">
                      <label class="card-label">{{ $store.getters['languageMixin/getStringFromLanguage']('Date') }}</label>
                      <fg-input v-model="formData.payment_deposit_date"
                                value-format="YYYY-MM-DD HH:MM:SS"
                                type="datetime-local"
                                placeholder="Chose date"
                      >
                      </fg-input>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label class="payment_text">
                      {{ $store.getters['languageMixin/getStringFromLanguage']('Payment of balance') + '(€' + registrationDetails.payment_balance_price + ')'}}
                    </label>
                    <div class="col-md-12">
                      <ValidationProvider
                        vid="payment_balance_status"
                        rules=""
                        name="The Payment Status"
                        v-slot="{ passed, failed,errors }">
                        <fg-select
                          name="status"
                          size="large"
                          filterable
                          clearable
                          :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Payment Status')"
                          :error="failed ? errors[0]: null"
                          :input-classes="'select-default'"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('Payment Status')"
                          :list="builderData.paymentStatusList"
                          :listItemLabel="'fr'"
                          :listItemValue="'value'"
                          v-model="formData.payment_balance_status">
                        </fg-select>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-12">
                      <ValidationProvider
                        vid="payment_balance_method"
                        rules=""
                        name="The Payment Method"
                        v-slot="{ passed, failed,errors }">
                        <fg-select
                          name="payment_method"
                          size="large"
                          filterable
                          clearable
                          :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Payment Method')"
                          :error="failed ? errors[0]: null"
                          :input-classes="'select-default'"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('Payment Method')"
                          :list="builderData.paymentMethodList"
                          :listItemLabel="'fr'"
                          :listItemValue="'value'"
                          v-model="formData.payment_balance_method">
                        </fg-select>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-12">
                      <label class="card-label">{{ $store.getters['languageMixin/getStringFromLanguage']('Date') }}</label>
                      <fg-input v-model="formData.payment_balance_date"
                                value-format="YYYY-MM-DD HH:MM:SS"
                                type="datetime-local"
                                placeholder="Chose date"
                      >
                      </fg-input>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <ValidationProvider
                      vid="notes"
                      rules=""
                      name="The Payment Notes"
                      v-slot="{ passed, failed,errors }">
                      <fg-text type="text"
                               :error="failed ? errors[0]: null"
                               :label="$store.getters['languageMixin/getStringFromLanguage']('Payment Notes')"
                               name="payment_notes"
                               fou
                               v-model="formData.payment_notes">
                      </fg-text>
                    </ValidationProvider>
                  </div>
                </div>
                <div v-if="registrationDetails.balance_installments && registrationDetails.balance_installments.length > 0">
                  <label>{{$store.getters['languageMixin/getStringFromLanguage']('Balance installments')}}</label>
                  <div class="row">
                    <div class="col-md-4" v-for="(installment, index) in registrationDetails.balance_installments">
                      <div class="card">
                        <div class="card-body align-content-center">
                         <div>
                           <label>{{$store.getters['languageMixin/getStringFromLanguage']('Amount')}}:</label>
                           <span>
                              <span>{{ installment.price}} €</span>
                              <span style="float: right">{{ installment.date}}</span>
                           </span>
                         </div>
                          <div>
                            <div class="col-md-12">
                              <ValidationProvider
                                :vid="'payment_status'+index"
                                rules=""
                                name="The Payment Status"
                                v-slot="{ passed, failed,errors }">
                                <fg-select
                                  name="status"
                                  size="large"
                                  filterable
                                  clearable
                                  :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Payment Status')"
                                  :error="failed ? errors[0]: null"
                                  :input-classes="'select-default'"
                                  :label="$store.getters['languageMixin/getStringFromLanguage']('Payment Status')"
                                  :list="builderData.paymentStatusList"
                                  :listItemLabel="'fr'"
                                  :listItemValue="'value'"
                                  v-model="installment.status">
                                </fg-select>
                              </ValidationProvider>
                            </div>
                            <div class="col-md-12">
                              <ValidationProvider
                                :vid="'payment_method' + index"
                                rules=""
                                name="The Payment Method"
                                v-slot="{ passed, failed,errors }">
                                <fg-select
                                  name="payment_method"
                                  size="large"
                                  filterable
                                  clearable
                                  :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Payment Method')"
                                  :error="failed ? errors[0]: null"
                                  :input-classes="'select-default'"
                                  :label="$store.getters['languageMixin/getStringFromLanguage']('Payment Method')"
                                  :list="builderData.paymentMethodList"
                                  :listItemLabel="'fr'"
                                  :listItemValue="'value'"
                                  v-model="installment.method">
                                </fg-select>
                              </ValidationProvider>
                            </div>
                            <div class="col-md-12">
                              <label class="card-label">{{ $store.getters['languageMixin/getStringFromLanguage']('Capture Date') }}</label>
                              <fg-input v-model="installment.capture_date"
                                        value-format="YYYY-MM-DD HH:MM:SS"
                                        type="datetime-local"
                                        placeholder="Chose date"
                              >
                              </fg-input>
                            </div>
                            <l-button @click="updateBalanceInstallment(installment)" type="danger" wide>{{ $store.getters['languageMixin/getStringFromLanguage']('Update') }}
                            </l-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-footer text-right">
          <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                    nativeType="submit"
                    type="info" wide>{{ $store.getters['languageMixin/getStringFromLanguage']('Submit') }}
          </l-button>
          <l-button @click="$router.push('/registrations/list')" type="danger" wide>{{ $store.getters['languageMixin/getStringFromLanguage']('Cancel') }}
          </l-button>
        </div>
      </card>
      </ValidationObserver>

    </div>

  </div>
</template>
<script>

import FgSelect from "@/components/Inputs/formGroupSelect";
export default {

  components: {
    FgSelect,
  },

  data() {
    return {
      id: undefined,
      formTitle: "",
      submitting: false,
      entityNotFoundError: false,
      memberDetails: {

      },
      registrationDetails: {
        id: '',
        admin_notes: '',
        status: '',
        payment_deposit_method: '',
        payment_deposit_status: '',
        payment_deposit_date: '',
        payment_deposit_billing_pro_invoice_id: '',
        payment_balance_method: '',
        payment_balance_status: '',
        payment_balance_date: '',
        payment_balance_billing_pro_invoice_id: '',
        payment_notes: '',
        payment_deposit_price: '',
        payment_balance_price: '',
      },
      imagePath: process.env.VUE_APP_SERVER_IMAGE_URL,
      formData: {
        admin_notes: '',
        status: '',
        payment_deposit_method: '',
        payment_deposit_status: '',
        payment_deposit_date: '',
        payment_deposit_billing_pro_invoice_id: '',
        payment_balance_method: '',
        payment_balance_status: '',
        payment_balance_date: '',
        payment_balance_billing_pro_invoice_id: '',
        payment_notes: '',
        payment_deposit_price: '',
        payment_balance_price: '',
      },
      builderData: {
        statusList: [],
        paymentStatusList: [],
        paymentMethodList: [],
      }
    };
  },

  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.itemView
    });

    this.id = this.$route.params['id'];
    this.formTitle = this.$store.getters['languageMixin/getStringFromLanguage']("Registration Details");
    if (this.id !== undefined) {
      this.getBuilderData();
      this.getItem();
    } else {
      this.$notify({
        message: this.$store.getters['languageMixin/getStringFromLanguage']("Registration Not Found"),
        timeout: 2000,
        type: 'danger'
      });
      this.loader.hide();
    }
  },


  methods: {
    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      request = this.axios.put("registrations/update/" + this.id, this.formData);
      successMessage = this.$store.getters['languageMixin/getStringFromLanguage']("Registration Updated Successfully");
      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        // this.$router.push("/registrations/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    showImage(image){
      if (image.startsWith('https://medias.ifcia-antoun.com')){
        return image;
      }
      return  process.env.VUE_APP_SERVER_IMAGE_URL + image;
    },
    getBuilderData() {
      this.axios.post("registrations/builder").then((response) => {
        this.builderData.registrationStatusList = response.data.registrationStatusList;
        this.builderData.paymentStatusList = response.data.paymentStatusList;
        this.builderData.paymentMethodList = response.data.paymentMethodList;
      }).catch((error) => {
        console.error(error);
      })
    },
    getItem() {
      this.axios.post("registrations/get", {id: +this.id}).then((response) => {
        this.memberDetails = response.data.member;
        this.registrationDetails = response.data;
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: this.$store.getters['languageMixin/getStringFromLanguage']("Registration Not Found"),
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },
    getValueOrDash(obj, key) {
      if (obj && obj[key]) {
        return obj[key];
      }
      return "-";
    },
    updateBalanceInstallment(installment){
      let request;
      let successMessage;
      this.submitting = true;

      request = this.axios.post("registrations/balance-installments/update", installment);
      successMessage = this.$store.getters['languageMixin/getStringFromLanguage']("Installment Updated Successfully");
      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        // this.$router.push("/registrations/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
        }
      }).finally(() => {
        this.submitting = false;
      })
    }
    // getCountryName(code){
    //   let countryName = '-';
    //   this.axios.get('https://restcountries.com/v3.1/alpha/'+code, {
    //     params: {
    //       lang: 'en'
    //     }
    //   })
    //     .then(response => {
    //       countryName = response.data[0].altSpellings[2];
    //     })
    //     .catch(error => {
    //       console.error(error);
    //     });
    //   return countryName;
    // },
  }
}
</script>

<style>
.header-content{
  display: flex;
  justify-content: space-between;
}
.typo-line{
  padding-left: 65px;
  margin-bottom: 20px;
}
.contact-info{
  margin-bottom: 20px;
}
.category{
  color: #888888;
}
.category-2{
  top: 11px !important;
}
.category-value{
  margin-left: 70px;
}
/*.message-content{*/
/*  font-size: 16px !important*/
/*}*/
.member-box {
  position: relative;
  border: 1px solid black;
  padding: 33px;
  padding-left: 30px;
  margin-bottom: 30px;
}
.title-label {
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: white;
  padding: 0 5px;
}
.member-profile-image{
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.student-checked{
  padding: 10px;
  background-color: #a1e82c;
  color: #ffffff;
}
.student-unchecked{
  padding: 10px;
  background-color: #ffbc67;
  color: #ffffff;
}
.payment_text{
  font-weight: bold!important;
  font-size: 15px!important;
  color: #000!important;
}
</style>
