<template>
    <el-dialog
      center
      @close="closeModal()"
      :visible="visible">
      <h5 style="text-align: center">{{ message }}</h5>
      <div v-for="template in templatesList">
        <l-radio
          :label="template.id"
          :text="template.subject"
          :name="'template'"
          v-model="value"
          >
      </l-radio>
      </div>
      
      
      <span slot="footer" class="dialog-footer">
        <button :disabled="submitting || !value" class="btn btn-info" @click="sendEmails()">{{ $store.getters['languageMixin/getStringFromLanguage']('Send Email') }}</button>
      </span>
    </el-dialog>
  </template>
  
  <script>
  import {Dialog} from 'element-ui';
  import {Radio as LRadio} from '@/components'
  
  export default {
    name: 'email-templates-modal',
  
    components: {
      [Dialog.name]: Dialog,
      LRadio
    },
  
    props: {
      visible: {
        type: Boolean,
        default: 0
      },
      message: {
        type: String,
        default: "Select Template",
      },
      templatesList: {
        type: Array,
        default: []
      },
      selectedUsers: {
        type: Array,
        default: []
      },
      
      
    },
    data() {
      return {
        value: null,
        submitting: false
      }
    },
    methods: {
  
      closeModal() {
        this.submitting = false
        this.$emit('close');
      },
      sendEmails(){
        this.submitting = true
        this.$emit('sendEmails', this. value);
      },
  
    }
  
  }
  </script>
  